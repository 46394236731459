.form-group {
    margin-bottom: 5px;
}

select[disabled], input[disabled], textarea[disabled] {
    background-color: rgb(235, 235, 228);
}

.tcc-fa-check-style.fa-stack-sm {
    &.fa-stack {
        font-size: 1em;
        height: 1em;
        line-height: 1;
        width: 1em;
        .fa-stack-2x { font-size: 1em !important }
        .fa-stack-1x { font-size: .6em !important; line-height: 1.6; }
     }
}

form {
    display: inline;
}

.visible-error {
    display: none;
}

/** App specific stuff */
table tr.selected > td {
    background: rgba(255,225,0,.2);
}

.table-very-condensed > thead > tr > th,
.table-very-condensed > tbody > tr > th,
.table-very-condensed > tfoot > tr > th,
.table-very-condensed > thead > tr > td,
.table-very-condensed > tbody > tr > td,
.table-very-condensed > tfoot > tr > td {
    padding: 2px;
    font-size: 10pt;
}

.text-italic {
    font-style: italic;
}

.cursor-pointer {
    cursor: pointer;
}

.sub-editor {
    th {
        padding-bottom: 0;
        padding-top: 0;
        border-bottom: 0;
        border-top: 0;
        background-color: #f4f4f4;
    }
    th, td {
        text-align: right;
    }
    colgroup, .border-right {
        border-right: 1px solid #888;
    }
    input {
        text-align: right;
        width: 40px;
    }
    tfoot {
        font-weight: bold;
    }
    tr:nth-child(1) th {
        text-align: center;
    }
    .hl-col, .hl-col .box {
        background-color: rgba(255,255,255,.7);
    }

}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/** these are used mostly in revenue section typically */
.input-subtle {
    width: 4rem;
    border: 0;
    outline: 1px solid #ccc;
    padding: 0px 1px;
    background: #f8f9fa;
    &:focus {
        outline: none;
    }
}

.horizontalDragHandle {
    width: 5px;
    background: #ddd;
    border: 1px #ccc solid;
    cursor: col-resize;
    height: 100%;
    overflow:hidden;
    &:after {
        content: "\02593";
        margin-left: -2px;
        vertical-align: middle;
        color: #ccc;
    }
    &.dragging {
        background: #daa;
    }
}

$led-row-height: 19px;
$led-amt-width: 82px;

ledger {
    background-color: #fff;
    display: flex;
    flex-direction:column;
    width:100%;
}
.LedgerPanelBody {
    margin: -16px -15px; // remove panel-body's padding.
    display: flex;
    flex-direction: column;
    background-color: rgb(255,255,253);
}

.LedgerArea {
    font-family: Calibri, "Lucida Console", Monaco, monospace;
    font-size: .8em;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: #fff;
    /*-webkit-transition: top ease-in .25s;
    transition: top ease-in .25s;
    overflow: scroll;*/
    background-color: rgb(255,255,253);
}

.LedgerHeaderContainer {
    position: -webkit-sticky;
    position: sticky;
    top: -16px; /** fix for container margin */
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    z-index: 2;
}

.LedgerHeaderDragHandle {
    /** acct titles are by default 270px, the width of the handle is 4px.
        Use that difference so that the handle overlaps the first date tile and isn't covered by the account titles. */
    padding-left: 274px;
    margin-left: -4px; /** negative space for handle element so that it won't push the titles*/
    position: sticky;
    z-index: 2; /** z-index to overwrite the first date title */
    background: rgb(255, 255, 253);
    top: 0;
    left: -20px; /** spacing for gutter */
}
.LedgerHeader {
    /** move left so that date titles begin right where account titles end (default 270px) */
    margin-left: -4px;
    display: flex;
    flex-direction: row;
}

.LedgerHeaderItems {
    flex: 1 1 auto;
}

.LedgerContent {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
}

.LedgerTitles {
    position: -webkit-sticky;
    position: sticky;
    left: -15px; /** fix for container margin */
    flex: 0 0 auto;
    width: 270px;
}

.LedgerBody {
    flex: 1 1 auto;
}

.x2 .LedgerHeaderDragHandle {
    /** x2 occurs when comparing against another ledger*/
    height: $led-row-height * 2;
}

.x2 .LedgerHeaderTitlesRow .led-amount-text {
    width: $led-amt-width * 2;
    max-width: $led-amt-width * 2;
    text-align: center;
}

.x2 .LedgerContent {
    /** needed so that sticky on LedgerTitles doesn't stop because container is too small*/
    width: $led-amt-width * 2 * 13; // 2 columns * (12 months + total col)
}



.led-row {
    white-space: nowrap;
    max-height: $led-row-height;
    height: $led-row-height;
    min-height: $led-row-height;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;
    background-color: rgb(255,255,253);
}

.led-row-notification-right {
    position: relative;
    right: 0;
    z-index: 2;
    text-align: right;
}

.led-row-notification-right span {
    cursor: pointer;
}

.led-title {
    display: block;
    padding: 0;
    box-sizing: border-box;
    white-space: nowrap;
    line-height: $led-row-height - 2px;
    height: $led-row-height;
    max-height: $led-row-height;
    /*position:relative;*/
    background-color: rgb(255,255,253);
    width: 100%;
    overflow: hidden;
}

.led-lvl-1 > .led-title {
    /*width:270px;*/
    margin-left: 2px;
    font-weight: bold;
}

.led-lvl-2 > .led-title {
    margin-left: 6px;
    /*width:270px;*/
}

.led-lvl-3 > .led-title {
    margin-left: 10px;
    /*width:260px;*/
}

.led-lvl-4 > .led-title {
    margin-left: 20px;
    /*width:250px;*/
    color: #333;
}

.led-lvl-5 > .led-title {
    margin-left: 25px;
    /*width:245px;*/
    color: #333;
}

.led-lvl-6 > .led-title {
    margin-left: 30px;
    /*width:240px;*/
    color: #333;
}

.led-lvl-7 > .led-title {
    margin-left: 35px;
    /*width:235px;*/
    color: #444;
}

.led-lvl-8 > .led-title {
    margin-left: 40px;
    /*width:240px;*/
    color: #444;
}

.led-lvl-9 > .led-title {
    margin-left: 45px;
    /*width:235px;*/
    color: #444;
}

.led-lvl-10 > .led-title {
    margin-left: 50px;
    /*width:230px;*/
    color: #444;
}

.led-lvl-11 > .led-title {
    margin-left: 55px;
    /*width:235px;*/
    color: #444;
}

.led-lvl-12 > .led-title {
    margin-left: 60px;
    /*width:230px;*/
    color: #444;
}

.led-row-parent {
    min-height: $led-row-height * 1.75;
    max-height: $led-row-height * 1.75;
    font-weight: bold;
}

    .led-row-parent > div {
        border-top: 1px solid #808080;
        /*padding-bottom: 15px;*/
    }

.led-row-subtotal {
    min-height: $led-row-height;
    max-height: $led-row-height;
}

    .led-row-subtotal > div {
        border-top: 1px solid #ddd;
        font-style: italic;
    }

    .led-row-subtotal > .led-title {
        font-weight: 600;
    }

.led-row-selected .led-amounts, .led-row-selected .led-title, .led-row-selected .led-row-notification-right {
    background: rgb(244,255,224);
}

.led-row-selected .led-amount div.hl-comparison-changes, .led-row-selected .led-amount span.hl-comparison-changes {
    background-color: rgb(210,255,245);
}
.led-row-selected .led-amount {
    background-color: rgb(244,255,224);
}

.led-row-selected .led-amount:nth-child(2n+1) {
    background-color: rgb(236,255,208);
}


.led-row-readonly .led-title, .led-row-readonly .led-amount-text {
    color: #aaa;
}

.led-amounts {
    flex: 0 0 auto;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    height: $led-row-height;
    min-height: $led-row-height;
    max-height: $led-row-height;
}

.led-title, .led-amounts {
    border-bottom: 1px solid #f8f8f8;
}



.led-amount {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    border-collapse: collapse;

    padding: 0;
    margin: 0;
    text-align: right;
}

    .led-amount:nth-child(2n+1) {
        background-color: rgb(252,252,250);
    }

    .led-amount input, .led-amount .led-amount-text, .led-amount .input-proxy {
        box-sizing: border-box;
        padding: 0 1px;
        width: $led-amt-width;
        max-width: $led-amt-width;
        margin: 0;
        height: $led-row-height;
        line-height: $led-row-height;
        vertical-align: top;
        max-height: $led-row-height;
    }
    .led-amount .input-proxy {
        -webkit-user-select: none;
        user-select: none;
    }
    .led-amount input, .led-amount .input-proxy {
        text-align: right;
        /*background: rgb(248,248,248);*/
        border: none;
        outline: none;
        padding: 0 1px 0 0;
        /*transition: ease-in background-color .25s;*/
        height: $led-row-height - 1px;
        max-height: $led-row-height - 1px;
    }


    .led-amount input, .led-amount:nth-child(2n+1) input {
        background: rgb(248,248,255);
    }

        .led-amount input:focus, .led-amount input:hover {
            background: rgb(248,248,255);
            border: none;
            outline: 1px solid #ccc;
        }

    .led-amount-text, .input-proxy {
        display: inline-block;
        box-sizing: border-box;
        padding-right: 1px;
        -moz-user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

/***** HIGHLIGHTS ******/
    .led-amount.hl-diff {
        background-color: rgb(255,250,192)
    }
        .led-amount.hl-diff:nth-child(2n+1) {
            background-color: rgb(247,242,188)
        }

.led-row-selected .led-amount.hl-diff {
    /*background-color: rgba(100,00,250,.15);*/
    background-color: rgb(247,255,192);
}

    .led-row-selected .led-amount.hl-diff:nth-child(2n+1) {
        /*background-color: rgba(250,250,250,.4);*/
        background-color: rgb(239,247,188);
    }

.led-amount div.hl-comparison-changes, .led-amount span.hl-comparison-changes {
    background-color: rgb(190,235,255)
}

/** if an input has is-invalid, but isn't a form-control make sure still indicates error */
input.is-invalid:not(.form-control) {
    border: 1px solid #f44;
}



    .led-amount:nth-child(2n+1) input.ng-invalid {
        background: rgba(254,236,236,.8);
    }




.led-toggleArea {
    display: inline-block;
    width: 12px;
}


.btn-led {
    padding: 1px 3px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 2px;
}

/***
    lem: Ledger Menu
*/

@media (max-width: 767px) {

    .LedgerArea {
        overflow: auto;
    }

    .LedgerPanelBody {
        position: static;
        margin: -15px;
        min-width:100vw;
    }
    body {
        overflow-x: auto;
    }
}

@media (min-width: 768px) {
    body {
        min-width: 1024px;
    }
}

@media print {
    body {
        padding-top: 0;
        transform: scale(.95);
        transform-origin: 0 0;
        min-width: 0;
    }

    h1 {
        text-align: center;
    }

    ledger .panel-heading, ledger .panel-subheader {
        display: none !important;
    }
    .panel, .panel-body {
        border: 0 !important;
        overflow: visible !important;
    }
    .visible-print-table-cell {
        display: table-cell !important;
    }

    .horizontalDragHandle {
        display: none;
    }

    .LedgerArea {
        overflow: auto;
        position: static;
        overflow: visible;
        zoom: .7;
    }

    .LedgerHeaderContainer {
        position: static;
    }
    .LedgerHeader {
        margin-left: 0;
    }
    .LedgerHeaderItems {
        padding-left: 300px;
    }
    .LedgerHeaderDragHandle {
        display: none;
    }
    .LedgerTitles {
        position: static;
        display: block;
        max-width: 300px;
        width: 300px;
    }

    .LedgerTitles .led-title {
        width: 300px;
    }

    .LedgerTitles > *:last-child {
        padding-bottom: 0; /* extra margin to make up for scroll bar height*/
    }

    .LedgerBody {
        position: static;
        display: block;
        /* rig to get to not move below ledger titles*/
        width: 1px;
        max-width: 1px;
    }

    .led-title, .led-amounts {
        border-bottom: .5px solid #ddd;
    }

    ledger-account:nth-child(2n+2) .led-amount, ledger-account:nth-child(2n+2) .led-title {
        background-color: rgb(250,250,250) !important;
    }

    .led-amount:nth-child(2n+1),
    .led-amount:nth-child(2n+1) input,
    .led-amount:nth-child(2n+1).input-proxy {
        background-color: rgb(245,245,245) !important;
    }

    .led-amount input, .led-amount .led-amount-text, .led-amount .input-proxy {
        box-sizing: border-box;
        padding: 0 1px;
        width: $led-amt-width;
        max-width: $led-amt-width;
        margin: 0;
        border: 0;
        outline: 0;
        min-height: $led-row-height;
        height: $led-row-height;
        max-height: $led-row-height;
    }

    .led-amount *:nth-child(2n+2) {
        border-right: 1px solid #ddd;
    }

}

.mr-1 {
    margin-right: 0.25rem !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.tooltipClass {
    position: absolute;
    background-color: white;
    border: 1px solid gray;
    padding: 5px;
    font-size: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); 
    z-index: 100000;
    max-width:325px;
    white-space:pre-wrap;
} 

.tooltipContainer {
    position: relative;
}